import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import stats2 from "../../images/mobiilsed-lavad-2.png"

const mobiilsedVali = () => (
  <Layout>
    <SEO title="Mobiilsed välilavad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>MOBIILSED VÄLILAVAD</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/lavad/mobiilsed-lavad-vali/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo antistaatilised">
            <h2>Mobiilsed välilavad</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link to="/lavad/mobiilsed-lavad/">
          <div className="epo epohoordemass">
            <h2>Mobiilsed lavad</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/lavad/mobiilsed-tribyynid/">
          <div className="epo isetasanduv-epomasskate">
            <h2>Mobiilsed tribüünid</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link to="/lavad/orkestraal/">
          <div className="epo treppidele">
            <h2>Koori- ja orkestrilavad</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/lavad/ratastel/">
          <div className="epo dekoratiivporandad">
            <h2>Ratastel lavad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/soiduki-lavad/">
          <div className="epo dekoratiivporandad">
            <h2>Sõidukite lavad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/statsionaarsed-tribyynid/">
          <div className="epo dekoratiivporandad">
            <h2>Statsionaarsed tribüünid</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/statsionaarsed/">
          <div className="epo dekoratiivporandad">
            <h2>Statsionaarsed lavad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/lavad/teleskoop-tribyynid/">
          <div className="epo dekoratiivporandad">
            <h2>Teleskooptribüünid</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
      </div>

      <div className="antistaatilised-wrapper">
        <div className="antistaatilised-text">
          <h3>Mobiilsed välilavad</h3>
          <p>
            ERGO platvormide seeria on loodud vastu pidama kõik
            ilmastikutingimused ja on varustatud vahelduvade teleskoopjalgadega,
            et korvata isegi kõige kriitilisemad tingimused.
          </p>
        </div>
        <div className="subpage-img platvorm-img">
          <img src={stats2} alt="Mobiilsed välilavad"></img>
        </div>
      </div>
    </div>
  </Layout>
)

export default mobiilsedVali
